// ------ Cookie related ------
export const supchatSessionHeaderName = 'X-SupChat-Session';
export const visitorIdentifierCookie = 'supchatvisitorclientsession';

// ------ User related ------
export const AGENT_ROLE = 1;
export const MANAGER_ROLE = 2;

// ------ Chat related ------
export const chatEvent = {
  ADD_TAG: 'add_tag',
  ARTICLE_PREDICTION: 'article_prediction',
  AUTH_TOKEN: 'auth_token',
  AUTH_TOKEN_DELETED: 'auth_token_deleted',
  AUTHORIZE: 'authorize',
  FILE_UPLOAD: 'file_upload',
  FORM_REQUEST: 'form_request',
  FORM_RESPONSE: 'form_response',
  I_HAVE_SEEN: 'i_have_seen',
  INACTIVITY_MESSAGE: 'inactivity_message',
  INTERACTION: 'interaction',
  JOIN: 'join',
  LEAVE: 'leave',
  MAINTAIN_STATUS: 'maintain_status',
  METADATA: 'metadata',
  NOTE: 'hidden_say',
  PING: 'ping',
  PONG: 'pong',
  PREDICTION: 'prediction',
  PROMPT_LOGIN: 'prompt_login',
  PROMPT_LOGIN_DENIED: 'prompt_login_denied',
  PROMPT_OVERLAY: 'prompt_overlay',
  PROMPT_OVERLAY_ACCEPTED: 'prompt_overlay_accepted',
  PROMPT_OVERLAY_DENIED: 'prompt_overlay_denied',
  PROMPT_OVERLAY_DIRECTLY: 'prompt_overlay_directly',
  PROMPT_OVERLAY_RESULT: 'prompt_overlay_result',
  REMOVE_TAG: 'remove_tag',
  SAY: 'say',
  SET_LANGUAGE: 'set_language',
  STATUS: 'status',
  TRANSLATE_NOW: 'translate_now',
  TYPING: 'typing',
  VISITOR_FILE_UPLOAD: 'visitor_file_upload',
  WAITING_INFO: 'waiting_info',
  WIDGET_COMMAND: 'widget_command',
};

export const reconnectIntervals = [
  Math.random() + 1,
  2,
  5,
  10,
  15,
  30,
  60,
  90,
  120,
  120,
  120,
  120,
  120,
];

/* websocket ping pong */
export const pingFrequency = 5_000; // milliseconds
export const pingMaxDiff = 10; // seconds
